// Variable overrides

$font-family-sans-serif: 'Roboto', sans-serif;

$gray-400: #ced4da; //!default;

$body-bg: #FF874A;
$body-color: white;
$primary: white;
$secondary: white;

$navbar-padding-y: 0;
$navbar-brand-font-size: 1.1rem;

$dropdown-padding-y: 0;
$dropdown-link-hover-bg: $gray-400;
$dropdown-item-padding-y: .7rem;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.4;
$headings-font-weight: 400;

$input-btn-font-size: $font-size-base * 1.125;
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 3rem;
$btn-border-radius: 2.5rem;

@import "~bootstrap/scss/bootstrap";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

body {
  background-image: linear-gradient(#FFA05E 0%, #FF874A 55%, #EE6B51 100%);
  height: 100vh;
}

.navbar {
  background-image: linear-gradient(to right, #FFA05E 0%, #FF874A 55%, #EE6B51 100%);
  border-bottom: 3px solid #EE6B51;
}

.navbar-brand img {
  height: 30px;
  margin-right: 1rem;
  margin-top: -2px;
}

.navbar .centered-logo {
  position: absolute;
  text-align: center;
  width: 240px;
  left: calc(50% - 120px);
  img {
    height: 25px;
    &.up2u{
      height: 35px;
      margin-right: 5px;
    }
  }
}

.btn-secondary {
  color: #313F84;
  &:hover {
    background-color: $gray-400;
  }
}

.hint{
  font-size: 0.8rem;
  margin: 10px 25px;
}

.mainView {
  margin-top: 12vh;
  .mainLogo {
    img {
      width: 7.7vw;
      min-width: 85px;
      margin-bottom: 0.5rem;
    }
    h1 {
      text-shadow: 0 0 10px #8c7573;
    }
  }
  form {
    margin-top: 7vh;
  }

  .mainForm {
    margin: auto;
    text-align: left;

    input#room {
      width: calc(100% - 1rem);
      border: 1px solid white;
      padding: 8px 28px;
      border-radius: 2rem;
      background: rgba(255, 255, 255, 0.15);
      color: white;
      font-size: 1.1rem;
      outline: none;
      &:focus {
        border-color: #BF5100;
      }
    }

  }
  @media (max-width: 992px) {
    #copyCol {
      padding-top: 1rem;
      text-align: center;
    }
  }
}

/*** Notifications ***/
/*** Example rules ***/
.redux-toastr .toastr {
  border-radius: 0px;
  .rrt-title {
  }
  .rrt-text {
  }
  &.rrt-info, &.rrt-success, &.rrt-warning, &.rrt-error, & button {
  }
  &.rrt-error {
  }
  &.rrt-success {
  }
  &.rrt-warning {
  }
  &.rrt-info {
  }
  .close-toastr {
    opacity: 0.6;
  }
}

.redux-toastr .top-right {
  top: 25vh;
  right: 15vw;
}

@media (min-width: 850px) and (max-width: 1200px) {
  .redux-toastr .top-right {
    right: 3vw;
  }
}

@media (max-width: 850px) {
  .redux-toastr .top-right {
    right: calc(50vw - 175px);
  }
}
